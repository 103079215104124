.footer {
    width: 100%;
    height: 10rem;
    background: lightsteelblue;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 2rem;
    margin-top: 4rem;
  }
  
  
  
  .footer p {
    color: black;
  }
  
