.project {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .project h1 {
    margin-top: 3rem;
    color: black;
    font-size: 2.5rem;
  }
  .project img {
    width: 43rem;
    border-radius: 10px;
  }
  
  .project p {
    font-size: 1.5rem;
    color: black;
  }
  
 

  .project-links{
    display: flex;
    justify-content: center;
    justify-content: space-evenly;
    width: 50%;
    color: lightsteelblue;
    font-size: 2rem;
    font-weight: bolder;
       cursor: pointer;
      }


      @media (max-width: 600px){

             
        .project h1 {
        font-size: 2rem;
        }
        .project img {
          width: 31rem;
          border-radius: 10px;
        }
        
        .project p {
          font-size: 2rem;
          color: black;
        }
        
      }

      @media (max-width: 450px){

             
        .project h1 {
        font-size: 1.5rem;
        }
        .project img {
          width: 21rem;
          border-radius: 10px;
        }
        
        .project p {
          font-size: 1rem;
          color: black;
        }

        .project-links{
          font-size: 1rem;
        }
        
      }